@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
html, body{
    position: relative;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 900;
}

p {
    margin: 0;
    font-weight: 400;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a,
button {
    outline: none;
    text-decoration: none;
}

.form-control{
    height: 50px;
    border-radius: 0;
    border: 3px solid #d1d1d1;
    font-size: 18px;
    box-shadow: 0px 2px 6px 1px hsla(0, 0%, 0%, .2);
}

.form-control:focus{
    border-color: #d1d1d1;
    box-shadow: 0px 2px 6px 1px hsla(0, 0%, 0%, .2);
}

header{
    width: 100%;
    background: #ffc423;
    padding: 15px 10px;
}

.custom-container{
    max-width: 760px;
    margin: 0 auto;
}

header p{
    font-size: 17px;
}

header a{
    color: #000;
}

header a:hover{
    color: #000;
}

footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    padding: 15px 10px;
    text-align: center;
}

footer p{
    font-size: 17px;
    color: #ffc423;
    font-weight: 700;
}

.payment-wrap{
    padding: 50px 0 100px;
}

.payment-wrap .logo{
    margin-bottom: 35px;
}

.payment-wrap .logo img{
    width: 240px;
}

.payment-wrap h3{
    color: #c41230;
    font-size: 30px;
    margin-bottom: 18px;
}

.payment-wrap .invoice-field{
    margin-bottom: 1px;
}

.payment-wrap .invoice-field .lable-sec{
    float: left;
    margin-right: 12px;
}

.payment-wrap .invoice-field .input-sec{
    float: right;
}

.payment-wrap .invoice-field .form-control{
    min-width: 360px;
}

.payment-wrap .invoice-field a{
    color: #2356e7;
}

.payment-wrap .invoice-field h4{
    font-weight: 400;
}

.payment-wrap .amount-field{
    margin: 25px 0;
    text-align: right;
}

.payment-wrap .amount-field label{
   font-size: 24px;
    line-height: 1;
    margin-bottom: 20px;
}

.payment-wrap .amount-field .form-control{
    max-width: 260px;
}

.payment-wrap .btn-submit{
    background: #425cfb;
    color: #000;
    font-size: 17px;
    border: 0;
    width: 194px;
    height: 42px;
    font-weight: 700;
}

.payment-wrap .btn-submit:hover{
    background: #6379ff;
}

.invoicetoggle{
    margin-bottom: 10px;
}

.invoicetoggle img{
    max-width: 100%;
}

.pay-method h1{
    margin-bottom: 10px;
    font-size: 35px;
}

.payment-wrap .pay-method h3{
    font-size: 24px;
    margin-bottom: 30px;
}

.pay-option {
    border: 2px solid #000;
    text-align: center;
    border-radius: 6px;
    max-width: 550px;
    margin: 0 auto;
}

.pay-option.payment{
    border: 2px solid #c1c1c1 !important;
    padding:8px;
    max-width: 619px !important;
}
.pay-option.payment p{
  
    padding-bottom:5px;
}

.pay-option .title{
    padding: 15px 10px;
    background: #ffc423;
    font-weight: 900;
    font-size: 18px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.pay-option .content ul li{
    padding: 20px 10px;
    border-bottom: 2px solid #000;
}

.pay-option .content ul li:last-child{
    border-bottom: 0;
}

.pay-option .content ul li .btn{
    min-width: 255px;
    background: #c41230;
    color: #fff;
    box-shadow: none;
    border-radius: 6px;
    padding: 8px;
}

.pay-option .content ul li .btn:hover{
        background: #e40a2f;
}

.payment-wrap .redirect{
    text-align: center;
    background: #449d44;
    border-radius: 6px;
    padding: 10px 5px;
}

.payment-wrap .redirect h4{
    color: #fff;
    font-size: 20px;
    margin-bottom: 10px;
}

.payment-wrap .redirect  .spinner-border-sm{
    width: 1.5rem;
    height: 1.5rem;
    border: 0.2em solid #fff;
    border-right-color: transparent;
}

.mail-check .content{
    padding: 20px 10px;
}

.mail-check .content p{
    margin-bottom: 15px;
    font-size: 17px;
}

.mail-check .content p:last-child{
    margin: 0;
}

.mail-check .content .address{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
    white-space: pre-line;
}

.mail-check .content p a{
    color: #c41230;
}

.pay-method,
.error{
    text-align: center;
}

.error h1 {
    font-size: 60px;
    margin-bottom: 30px;
    line-height: 1;
}

.error .btn {
    background: #c41230;
    color: #fff;
    box-shadow: none;
    border-radius: 6px;
    padding: 12px 18px;
    line-height: 1;
    text-transform: uppercase;
    font-size: 13px;
}

.error .error-wrap{
    background: #ffc423;
    padding: 20px;
    border: 2px solid #000;
    border-radius: 6px;
}

/*==========  Responsive CSs  ==========*/

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 991px) {
    .custom-container{
        padding: 0 25px;
    }
    .payment-wrap .invoice-field .lable-sec{
        margin: 0 0 18px;
    }
}

@media only screen and (max-width: 767px) {
    body{
        margin: 0;
    }
    .payment-wrap {
        padding: 40px 0;
    }
    footer{
        position: relative;
    }
    .payment-wrap .invoice-field .lable-sec,
    .payment-wrap .invoice-field .input-sec{
        float: right;
    }
}

@media only screen and (max-width: 480px) {
    .payment-wrap .input-sec .form-control{
        width: 100%;
        min-width: inherit;
    }
    .payment-wrap .amount-field .form-control{
        max-width: inherit;
    }
    .payment-wrap .invoice-field h4,
    .payment-wrap .amount-field label{
        font-size: 20px;
    }
}

@media only screen and (max-width: 320px) {

}

.amount-field .amount-form {
    margin-bottom: 25px;
}

.amount-field .amount-form label {
    display: inline-block;
    margin: 0;
}

.amount-field .amount-form .form-control {
    display: inline-block;
    margin-left: 20px;
}

 .modal-title.h4{
    color: #c41230;
    font-size:20px;
 }
 .table td span.amount{
    color: green;
    font-weight: bold;
 }
.Toastify__toast--success{
    background: #468847 !important;
}

.address{
    white-space: pre-line;
}
.Toastify__toast--error
{
    background: #c41230 !important;
    
}

.g-recaptcha {
transform:scale(0.77);
-webkit-transform:scal(0.77);
transform-origin:0 0;
-webkit-transform-origin:0 0;
} 
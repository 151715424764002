html, body{
    position: relative;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 900;
}

p {
    margin: 0;
    font-weight: 400;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a,
button {
    outline: none;
    text-decoration: none;
}

.form-control{
    height: 50px;
    border-radius: 0;
    border: 3px solid #d1d1d1;
    font-size: 18px;
    box-shadow: 0px 2px 6px 1px hsla(0, 0%, 0%, .2);
}

.form-control:focus{
    border-color: #d1d1d1;
    box-shadow: 0px 2px 6px 1px hsla(0, 0%, 0%, .2);
}

header{
    width: 100%;
    background: #ffc423;
    padding: 15px 10px;
}

.custom-container{
    max-width: 760px;
    margin: 0 auto;
}

header p{
    font-size: 17px;
}

header a{
    color: #000;
}

header a:hover{
    color: #000;
}

footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    padding: 15px 10px;
    text-align: center;
}

footer p{
    font-size: 17px;
    color: #ffc423;
    font-weight: 700;
}

.payment-wrap{
    padding: 50px 0 100px;
}

.payment-wrap .logo{
    margin-bottom: 35px;
}

.payment-wrap .logo img{
    width: 240px;
}

.payment-wrap h3{
    color: #c41230;
    font-size: 30px;
    margin-bottom: 18px;
}

.payment-wrap .invoice-field{
    margin-bottom: 1px;
}

.payment-wrap .invoice-field .lable-sec{
    float: left;
    margin-right: 12px;
}

.payment-wrap .invoice-field .input-sec{
    float: right;
}

.payment-wrap .invoice-field .form-control{
    min-width: 360px;
}

.payment-wrap .invoice-field a{
    color: #2356e7;
}

.payment-wrap .invoice-field h4{
    font-weight: 400;
}

.payment-wrap .amount-field{
    margin-bottom: 50px;
}

.payment-wrap .amount-field label{
   font-size: 24px;
    line-height: 1;
    margin-bottom: 20px;
}

.payment-wrap .amount-field .form-control{
    max-width: 260px;
}

.payment-wrap .btn-submit{
    background: #099b10;
    color: #000;
    font-size: 17px;
    border: 0;
    width: 194px;
    height: 42px;
    font-weight: 700;
}

.payment-wrap .btn-submit:hover{
    background: #036d33;
}
.payment-wrap .btn-submit:disabled {
    opacity: 0.5;
}
.payment-wrap .btn-pay {
    background: #e72535;
    color: #fff;
    font-size: 17px;
    border: 0;
    width: auto;
    height: 42px;
    padding: 5px;
    font-weight: 700;
    margin-top: 10px;
}
.payment-wrap .btn-pay:hover {
    background: #a00e15;
}
.payment-wrap .btn-pay:disabled {
    opacity: 0.5;
}
.active{
    border: 4px solid #000 !important; 
}
.invoicetoggle{
    margin-bottom: 10px;
}

.invoicetoggle img{
    max-width: 100%;
}

.pay-method h1{
    margin-bottom: 10px;
    font-size: 35px;
}

.payment-wrap .pay-method h3{
    font-size: 24px;
    margin-bottom: 30px;
}

.pay-option {
    border: 2px solid #000;
    text-align: center;
    border-radius: 6px;
    max-width: 550px;
    margin: 0 auto;
}

.pay-option .title{
    padding: 15px 10px;
    background: #ffc423;
    font-weight: 900;
    font-size: 18px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.pay-option .content ul li{
    padding: 20px 10px;
    border-bottom: 2px solid #000;
}

.pay-option .content ul li:last-child{
    border-bottom: 0;
}

.pay-option .content ul li .btn{
    min-width: 255px;
    background: #c41230;
    color: #fff;
    box-shadow: none;
    border-radius: 6px;
    padding: 8px;
}

.pay-option .content ul li .btn:hover{
        background: #e40a2f;
}

.payment-wrap .redirect{
    text-align: center;
    background: #449d44;
    border-radius: 6px;
    padding: 10px 5px;
}

.payment-wrap .redirect h4{
    color: #fff;
    font-size: 20px;
    margin-bottom: 10px;
}

.payment-wrap .redirect  .spinner-border-sm{
    width: 1.5rem;
    height: 1.5rem;
    border: 0.2em solid #fff;
    border-right-color: transparent;
}

.mail-check .content{
    padding: 20px 10px;
}

.mail-check .content p{
    margin-bottom: 15px;
    font-size: 17px;
}

.mail-check .content p:last-child{
    margin: 0;
}

.mail-check .content .address{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 25px;
}

.mail-check .content p a{
    color: #c41230;
}

.pay-method,
.error{
    text-align: center;
}

.error h1 {
    font-size: 60px;
    margin-bottom: 30px;
    line-height: 1;
}

.error .btn {
    background: #c41230;
    color: #fff;
    box-shadow: none;
    border-radius: 6px;
    padding: 12px 18px;
    line-height: 1;
    text-transform: uppercase;
    font-size: 13px;
}

.error .error-wrap{
    background: #ffc423;
    padding: 20px;
    border: 2px solid #000;
    border-radius: 6px;
}

.print-wrap{
    border: 1px solid #ddd;
    padding: 20px;
}

.receipt-header{
    position: relative;
    margin-bottom: 50px;
}

.receipt-header .logo{
    position: absolute;
    right: 10px;
    top: 10px;
    margin: 0;
}

.receipt-header .logo img{
    width: 200px;
}

.receipt-header .address{
    margin-bottom: 20px;
}

.receipt-header .paid{
    text-transform: uppercase;
    margin: 0 auto;
    width: 120px;
    text-align: center;
    color: #0a0 !important;
    border: 5px solid #0a0;
    border-radius: 8px;
    font-size: 30px;
    font-weight: 900;
    padding: 0 15px;
    -moz-transform: rotate(-23deg);
    -webkit-transform: rotate(-23deg);
}

.print-wrap h2{
    text-align: center;
    margin-bottom: 20px;
}

.print-wrap table{
    width: 100%;
}

.print-wrap table tr td{
    vertical-align: top;
    padding: 5px;
}

.pricing-table{
    margin-top: 50px;
}

.pricing-table tr {
    border-bottom: 1px solid #ddd;
}

.pricing-table tr:last-child{
    border: 0;
}

.pricing-table tr th{
    padding: 8px 5px;
    font-weight: 900;
}

.pricing-table tr td{
    padding: 8px 5px !important;
}

/*==========  Responsive CSs  ==========*/

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 991px) {
    .custom-container{
        padding: 0 25px;
    }
    .payment-wrap .invoice-field .lable-sec{
        margin: 0 0 18px;
    }
}

@media only screen and (max-width: 767px) {
    body{
        margin: 0;
    }
    .payment-wrap {
        padding: 40px 0;
    }
    footer{
        position: relative;
    }
    .payment-wrap .invoice-field .lable-sec,
    .payment-wrap .invoice-field .input-sec{
        float: right;
    }
}

@media only screen and (max-width: 480px) {
    .payment-wrap .input-sec .form-control{
        width: 100%;
        min-width: inherit;
    }
    .payment-wrap .amount-field .form-control{
        max-width: inherit;
    }
    .payment-wrap .invoice-field h4,
    .payment-wrap .amount-field label{
        font-size: 20px;
    }
    .receipt-header .logo{
        position: inherit;
    }
}

@media only screen and (max-width: 320px) {

}

.text-area-content
{
  white-space: pre-wrap;
}
